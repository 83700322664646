import { functions } from '../config/firebase';

interface ResultResponse {
  imageUrl: string;
}

/**
 * Fetches the post's result image. Vote results overlay the post image.
 *
 * @param {string} postId
 * @returns {Promise<string>} URL of the image (publicly accessible).
 */
async function fetchResultImageURL(postId: string): Promise<string> {
    console.log('[PostResult] fetchResultImage', postId);

    // call function with post id
    const generateResultImage = functions.httpsCallable('generateResultImage');
    const result = await generateResultImage({ postId: postId });
    // cast to response type
    const response = result.data as ResultResponse;
    // return url
    return response.imageUrl;
}

export default fetchResultImageURL;
