/*
 * Warning: Do not import css or other static assets on this page.
 *
 * OG Tag prerendering involves rewriting from hosting onto a prerender function which injects
 * og tags. Only html is returned to the browser. Imported css etc. is not.
 */

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import fetchResultImageURL from '../api/posts';
import KoTStoreButton from './KOTStoreButton';
import Loader from 'react-loader-spinner';
import AspectRatio from './Ratio/AspectRatio';
import { device } from '../utils/utils';
import queryString from 'query-string';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`;

// serves as a container for the image
const StyledAspectRatio = styled(AspectRatio)`
    min-width: 300px;
    max-width: 60%;
    @media ${device.laptop} {
        min-width: 500px;
    }
    background-color: #f5f5f5;
`;

const CenterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: auto;
    height: auto;
`;

const TextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const ErrorParagraph = styled.p`
    color: #343434;
`;

const PostImage = styled.img``;

type RouteParams = { postId: string | undefined };

// Component

const PostResult = ({ match }: RouteComponentProps<RouteParams>) => {
    const [viewState, setViewState] = useState<'loading' | 'error' | 'nominal'>('loading');
    const [postResultUrl, setPostResultUrl] = useState('');

    // parse postId from path /post/1324
    let {
        params: { postId }
    } = match;

    if (postId === undefined) {
        // parse post id from query params /post?post=1234
        const parsed = queryString.parse(location.search);
        const value = parsed.postId;
        if (typeof (value) === 'string') {
            postId = value;
        }
    }

    // console.log('[PostResult] id:', postId);

    // fetch external data
    useEffect(() => {
        // console.log('[PostResult] useEffect called');
        if (postId === undefined) {
            setViewState('error');
        } else {
            fetchResultImageURL(postId)
                .then((url) => {
                    console.log(url);
                    setPostResultUrl(url);
                    setViewState('nominal');
                })
                .catch((error) => {
                    console.log('[PostResults]', error);
                    setViewState('error');
                });
        }

        return () => {
            // console.log('[PostResult] useEffect cleanup called');
            // what clean up actions would I need?
        };
    }, [postId, postResultUrl]);

    const errorComponent = () => {
        return (
            <TextContainer>
                <ErrorParagraph>Please try again later.</ErrorParagraph>
            </TextContainer>
        );
    };

    const iosBannerArgumentURL = postId ? `keeportoss://post/${postId}` : 'keeportoss://';

    return (

        <StyledContainer>
            <Helmet>
                <meta name="title" content="Keep or Toss" />
                <meta name="description" content="The social voting app!" />
                <meta name="theme-color" content="#ff6b5d" />
                <meta name="og:title" content="Vote Now!" />
                <meta name="og:description" content="Cast your vote on Keep or Toss" />
                <meta name="og:image" content={postResultUrl} />
                <meta name="og:type" content="website" />
                <meta name="og:site_name" content="Keep or Toss" />
                <meta name="og:url" content={location.href} />
                <meta name="twitter:card" content="summary_card" />
                <meta name="twitter:description" content="Vote Now!" />
                <meta name="twitter:image" content={postResultUrl} />
                <meta name="twitter:app:name:iphone" content="Keep or Toss" />
                <meta name="twitter:app:id:iphone" content="1528216626" />
                <meta name="apple-itunes-app" content={`app-id=1528216626, app-argument=${iosBannerArgumentURL}`} />
            </Helmet>
            <div style={{ flex: 1, minHeight: '30px' }} />
            <StyledAspectRatio ratio="4/5">
                {(viewState === 'loading')
                    ? <CenterContainer>
                        <Loader
                            type='TailSpin'
                            color='#ff6b5d'
                            height={60}
                            width={60}
                            timeout={1000 * 60} // 1 min
                        />
                    </CenterContainer>
                    : ((viewState === 'error')
                        ? errorComponent()
                        : <PostImage src={postResultUrl}></PostImage>)
                }
            </StyledAspectRatio>
            <div style={{ flex: 1, minHeight: '30px' }} />
            <KoTStoreButton />
            <div style={{ flex: 2, minHeight: '40px' }} />
        </StyledContainer>
    );
};

export default PostResult;
