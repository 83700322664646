import baseStyled, { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
    primaryDark: '#0D0C1D',
    primaryLight: '#EFFFFA',
    primaryHover: '#343078',
    mobile: '576px',
    borderRadius: '4px'
};

export type Theme = typeof theme;
export const styled = baseStyled;
