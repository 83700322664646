import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  width: 100%;
`;

const StyledUL = styled.ul`
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 650px;
`;

const StyledA = styled.a`
    font-size: 14px;
    font-weight: 300;
    color: #2d2d2d;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
  `;

export default function Footer() {
    return (
        <StyledFooter>
            <StyledUL>
                <li>
                    <StyledA href="https://www.keeportoss.com/terms-of-use/">TERMS OF SERVICE</StyledA>
                </li>
                <li>
                    <StyledA href="https://www.keeportoss.com/privacy-policy/">PRIVACY POLICY</StyledA>
                </li>
                <li>
                    <StyledA href="https://www.keeportoss.com/community-guidelines/">COMMUNITY GUIDELINES</StyledA>
                </li>
            </StyledUL>
        </StyledFooter>
    );
}
