/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import KOTLogo from '../../assets/kot_logo.svg';

const StyledNav = styled.nav`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 10vh;
    width: 100%;
    min-height: 60px;
    background-color: #e7dfd4;
    color: #ff6b5d;
  `;

const StyledNavLogo = styled.div`
    font-size:22px;
    font-weight: 700;
    text-transform: uppercase;
`;

const LogoImage = styled.img`
    width: 300px;
    height: auto;
    box-sizing: border-box;
    background-color: #e7dfd4;
  `;

export default function NavBar() {
    // const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <StyledNav>
                <StyledNavLogo>
                    <Link to='/'><LogoImage src={KOTLogo} /></Link>
                </StyledNavLogo>
                {/* <ul
                    className="nav-links"
                    style={{
                        transform: isOpen ? 'translateX(0px)' : ''
                    }}
                >
                <i
                    onClick={() => setIsOpen(!isOpen)}
                    className="fas fa-bars burger"
                ></i> */}
            </StyledNav>
        </>
    );
}
