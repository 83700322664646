import React from 'react';
import { Helmet } from 'react-helmet';
import KoTStoreButton from '../components/KoTStoreButton';
import styled from 'styled-components';
import Footer from './Footer';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const StyledHome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 650px;
  margin-top: 50px;
`;

const StyledP = styled.p`
  text-align: center;
  margin-bottom: 10px;
  margin-left: 80px;
  margin-right: 80px;
  font-size: 14px;
  font-weight: 300px;
  line-height: 180%;
`;

const StyledImg = styled.img`
  margin-top: 50px;
  margin-bottom: 60px;
  width: 100%; 
  object-fit: scale-down; 
`;

export default function Home() {
    console.log('[Home Component]');
    return (
        <>
            <Helmet>
                <title>Keep or Toss</title>
                <meta name="description" content="The social voting app." />
                <meta name="apple-itunes-app" content="app-id=1528216626" />
            </Helmet>
            <StyledContainer>
                <StyledHome>
                    <StyledP>The Social Voting App that helps you streamline your closet. Simply upload photos of your clothes and let our supportive community vote on what you should keep and what you should toss.</StyledP>
                    <StyledP>Lend your expertise and help others by voting on what they upload.</StyledP>
                    <StyledImg src="https://www.keeportoss.com/wp-content/uploads/2020/07/phone_shots.png" />
                    <KoTStoreButton />
                </StyledHome>
                <div style={{ flex: 1 }} />
                <Footer></Footer>
            </StyledContainer>
        </>
    );
}
