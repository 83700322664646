import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-rendering: optimizeLegibility;
  }

  #root {
    height: 100%;
    width: 100%;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }
  nav .nav-links {
    display: flex;
    justify-content: space-around;
    width: 32%;
  }
  nav .nav-links a {
    font-size: 18px;
    font-weight: 500;
    color: #2d2d2d;
    cursor: pointer;
  }
  
  nav .nav-links a:hover {
    font-size: 18px;
    font-weight: 500;
    background-color: #ff6b5d;
  }
  
  nav .burger {
    font-size: 28px;
    display: none;
    cursor: pointer;
  }

  @media all and (max-width: 768px) {
    nav .burger {
      display: block;
    }
    
    nav .nav-links {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      height: 150pt;
      background-color: #e7dfd4;
      padding: 20px; 
      width: 50%;
      transform: translateX(-500px);
      transition: 0.27s ease-in-out;
    }
    
    nav .nav-links li {
     padding: 15px;
     border-bottom: 1px solid #ccc;
    }
    
    nav .nav-links li:last-of-type {
      border-bottom: 0px solid #ccc;
    }
    
    nav .nav-links a:hover {
      font-size: 18px;
      font-weight: 500;
      background-color: #ff6b5d;
    }
  }
  `;
