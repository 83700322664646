// Credit: https://github.com/roderickhsiao/react-aspect-ratio

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  [style*="--aspect-ratio"] > img {
    height: auto;
  }
  
  [style*="--aspect-ratio"] {
    position: relative;
  }
  
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    width: 100%;
  }
  
  @supports not (aspect-ratio: 1/1) {
    [style*="--aspect-ratio"]::before {
      height: 0;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
  }
  
  @supports (aspect-ratio: 1/1) {
    [style*="--aspect-ratio"]::before {
      aspect-ratio: calc(var(--aspect-ratio));
    }
  }
`;

const CUSTOM_PROPERTY_NAME = '--aspect-ratio';
const DEFAULT_CLASS_NAME = 'react-aspect-ratio-placeholder';

type Props = {
    ratio: string | number, // eslint-disable-line
    style: React.CSSProperties,
    children: React.ReactNode// Element<any>
};

const AspectRatio = (props: Props) => {
    const {
        children,
        ratio,
        style,
        ...restProps
    } = props; // eslint-disable-line no-unused-vars

    const newStyle = {
        ...style,
        // https://github.com/roderickhsiao/react-aspect-ratio/commit/53ec15858ae186c41e70b8c14cc5a5b6e97cb6e3
        [CUSTOM_PROPERTY_NAME]: `(${ratio})`
    };

    return (
        <Wrapper>
            <div {...restProps} style={newStyle}>
                {children}
            </div>
        </Wrapper>
    );
};

AspectRatio.defaultProps = {
    className: DEFAULT_CLASS_NAME,
    ratio: 1
};

export default AspectRatio;
