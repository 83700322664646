import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import Home from './components/Home';
import PostResult from './components/PostResult';
import { GlobalStyles } from './styles/global';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import { Helmet } from 'react-helmet';

const StyledWrapper = styled.div`
  height: calc(100% - 10vh);
  width: 100%
`;

/*
This App component is rendered by both the client and the server. Do not wrap in a Browser Router.
https://stackoverflow.com/a/45043410
*/
function App() {
    return (
        <>
            <Helmet>
                <meta name="title" content="Keep or Toss" />
            </Helmet>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <NavBar></NavBar>
                {/* Wrapping Switch in BrowserRouter causes SSR to fail. */}
                <StyledWrapper>
                    <Switch>
                        <Route path="/post/:postId" component={PostResult} />
                        <Route path="/post" component={PostResult} />
                        <Route exact path="/" component={Home} />
                    </Switch>
                </StyledWrapper>
            </ThemeProvider>
        </>
    );
}

export default App;
