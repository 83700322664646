import React from 'react';
import MobileStoreButton from 'react-mobile-store-button';

export default function KoTStoreButton() {
    const iOSURL = 'https://apps.apple.com/us/app/keep-or-toss/id1528216626';

    return (
        <MobileStoreButton store='ios' url={iOSURL} width={160} linkProps={{ title: 'iOS Store Button' }}></MobileStoreButton>
    );
}
